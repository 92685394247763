import Header from '@/layout/component/header/community'
import Sidebar from '@/layout/component/sidebar'
import MobileHeader from '@/layout/component/header/community/mobile'
import Footer from '@/layout/component/footer/community'
import MobileFooter from '@/layout/component/footer/community/mobile'

import { getWindowSize } from '@helper/util/useWindowSize'

interface Props {
  children: any
}

const community = ({
  children
}:
Props) => {
  const windowSize = getWindowSize()
  return (
    <>
      {windowSize.width >= 640
        ? (
        <div className='bg-white'>
          <Header />
          <div className="relative w-full">{children}</div>
          <Footer />
        </div>
          )
        : (
        <div className={'bg-gray-100 h-screen overflow-hidden relative'}>
          <div className="h-full overflow-auto absolute">
            <MobileHeader />
            {children}
            <MobileFooter />
          </div>
          <Sidebar />
        </div>
          )}
    </>
  )
}

export default community
