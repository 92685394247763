import Navigation from './navigation'
import { WeatherInformation } from '@/layout/component/header/community/weather'

import { setSidebar } from '@/general/state'

import { useDispatch } from 'react-redux'

import Link from 'next/link'

const Header = () => {
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(setSidebar(true))
  return (
      <div className='bg-white sticky top-0 z-50 shadow-lg'>
        <div className='grid gap-y-3 px-5 py-3 shadow'>
          <div className='
            flex gap-x-3 justify-between items-center
            max-w-full overflow-hidden
          '>
            <div className="w-1/6 cursor-pointer" onClick={toggleSidebar}>
              <img className="" src="/menu/Hamburger.png" />
            </div>
            <select className='bg-transparent whitespace-nowrap overflow-hidden text-ellipsis w-full'>
              <option value="">Holywing Kemang</option>
              <option value="">Mall Aeon Tanjung Barat</option>
              <option value="">Grapari Telkomsel</option>
            </select>
            <select className='bg-transparent whitespace-nowrap overflow-hidden text-ellipsis w-full'>
              <option value="">Mall Aeon Tanjung Barat</option>
              <option value="">Holywing Kemang</option>
              <option value="">Grapari Telkomsel</option>
            </select>
          </div>
          <div className='flex gap-2 justify-between items-center'>
            <WeatherInformation />
            <div className='flex gap-3 justify-between items-center' >
                <Link href="/chat">
                  <img className="" src="/menu/Add.png" />
                </Link>
                <Link href="/chat">
                  <img className="" src="/menu/Notification.png" />
                </Link>
                <Link href="/chat">
                  <img className="" src="/menu/Chat.png" />
                </Link>
                <Link href="/chat">
                  <img className="" src="/menu/Category.png" />
                </Link>
            </div>
          </div>
        </div>
        <div className='text-sm flex gap-x-3 justify-center items-center py-3'>
          <Navigation
            navigation={[
              { title: 'Home', link: '' },
              { title: 'Buy & Sell', link: '/buyandsell' },
              { title: 'Around', link: '/around' },
              { title: 'My Sellon', link: '/mysellon' }
            ]}
          />
        </div>
      </div>

  )
}

export default Header

// import Link from 'next/link'
// import { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'

// import Select from '@components/atom/Select'
// import Menu from '@components/atom/IconMenu'

// import Authentication from '@/auth/template'
// import { WeatherInformation } from '@/molecule/community/Weather'

// import { getAccountState, signOut } from '@/auth/state'
// import { getLandingState, selectLanguage } from '@/landing/state'
// import { getGeneralState, selectEnvironment } from '@/general/state'

// import useModal from '@helper/util/useModal'
// import { getFirstName } from '@helper/util/useCase'

// import styled from 'styled-components'

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   width: 100vw;
// `
// const Top = styled.div`
//   align-items: center;
//   background: #fff;
//   display: flex;
//   padding: 0.5rem 0;
//   width: 100%;
// `
// const GnB = styled.div`
//   align-items: center;
//   background-color: #fff;
//   display: flex;
//   padding: 0.5rem 0;
//   width: 100%;
// `

// const Header = () => {
//   const dispatch = useDispatch()
//   const { callModal } = useModal()

//   const { address, isSignedIn, userProfile } = useSelector(getAccountState)
//   // const { language } = useSelector(getLandingState);
//   const { typeEnvironment } = useSelector(getGeneralState)

//   const { firstName, image } = userProfile

//   // const setLanguage = (lang) => dispatch(selectLanguage(lang));
//   const setEnvironment = (lang) => dispatch(selectEnvironment(lang))
//   return (
//     <Container className="sticky top-0 z-50 shadow-1">
//       <Top className="container-sm mx-auto justify-between">
//         <div className="flex">
//           <Menu tooltip="Menu" className="padded">
//             <img src="/menu/Hamburger.png" />
//           </Menu>
//           <Select
//             disabled={!address || address.length === 0}
//             icon={<img src="/media/icon_png/Location.png" alt="" />}
//             options={
//               address && address.length > 0
//                 ? address.map((_address) => ({
//                   label: _address.general_location,
//                   value: _address.id
//                 }))
//                 : [{ label: 'Location', value: '' }]
//             }
//           />
//         </div>
//         <Select
//           className="with-margin"
//           options={[
//             { label: 'All', value: '' },
//             { label: 'Same Building & Street', value: 'sameStreet' },
//             { label: 'Neighborhoods', value: 'neighborhood' }
//           ]}
//           onClick={
//             !isSignedIn
//               ? (e) => {
//                   e.preventDefault()
//                   e.stopPropagation()
//                   callModal(<Authentication />)
//                 }
//               : null
//           }
//           onChange={(e) => setEnvironment(e.target.value)}
//           value={typeEnvironment}
//         />
//       </Top>
//       <GnB className="container-sm mx-auto justify-between">
//         <WeatherInformation />
//         <div className="flex">
//           <Menu tooltip="Notification" className="padded">
//             <img src="/menu/Notification.png" />
//           </Menu>
//           <Menu tooltip="Chat" className="padded">
//             <Link href="/chat">
//               <img src="/menu/Chat.png" />
//             </Link>
//           </Menu>
//           <Menu tooltip="Category" className="padded">
//             <img src="/menu/Category.png" />
//           </Menu>
//         </div>
//       </GnB>
//     </Container>
//   )
// }

// export default Header
