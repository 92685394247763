function Footer () {
  return (
        <div className='overflow-hidden border-t bg-white'>
            <div className="container-sm px-16 py-5 mx-auto bg-white">
                <div className=''>
                    <p className="text-sm text-center mb-2">To get a better app experience</p>
                    <p className="text-base text-center mb-5 font-bold">Download SellOn Apps</p>
                    <div className='flex gap-3'>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.csi.sellon"
                        >
                            <img className="w-full" src="/media/footer/googleplay-button.png" alt="" />
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://apps.apple.com/id/app/sellon-online-marketplace/id1462394563"
                        >
                            <img className="w-full" src="/media/footer/appstore-button.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="
                    container-sm p-3
                    bg-black gap-2 text-white
                    flex justify-center items-center
                "
            >
                <img className='h-5' src="/icons/icon-192x192.png" alt="" />
                <p style={{ fontSize: '.7rem' }}> © ALL RIGHTS RESERVED BY PT SELLON TECH INDONESIA </p>
            </div>
        </div>
  )
}

export default Footer
