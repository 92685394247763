import axios from 'axios'
import { urlGenerator } from '../../../store/api'

// axios.defaults.baseURL = 'https://cms-sgmaster.sellon.net/'
// axios.defaults.baseURL = 'https://dev2-cms-sgmaster.sellon.net/'
axios.defaults.baseURL = 'https://cms-idmaster.sellon.net/'

export function getCarouselBanner (payload) {
  const url = 'api_admin/banner_carousel/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}

export function getEventBanner (payload) {
  const url = 'api_mobile/event_banner/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}
