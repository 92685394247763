import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './initial'
import type { RootState } from '../../../store/config'

// Define the initial state using that type

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    requestEventBanner: (state) => {
      state.eventBanner = { ...state.eventBanner, pending: true }
    },
    setEventBanner: (state, action: PayloadAction<Array>) => {
      const { payload } = action
      state.eventBanner = { ...state.eventBanner, pending: false, data: payload.event_banner }
    },
    requestCarouselBanner: (state) => {
      state.carouselBanner = { ...state.carouselBanner, pending: true }
    },
    setCarouselBanner: (state, action: PayloadAction<Array>) => {
      const { payload } = action
      state.carouselBanner = { ...state.carouselBanner, pending: false, data: payload.banner_list }
    }
  }
})

export const {
  requestCarouselBanner, setCarouselBanner,
  requestEventBanner, setEventBanner
} = eventSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getEventState = (state: RootState) => state.events

export default eventSlice.reducer
