
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    /* input[type=text], input[type=password], select, textarea {
        margin: .75em 0;
        width: 100%;
        padding: 6px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        transition: all .5s ease-in;
    } */
    .sample{
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        font-size: 12px;
        line-height: 18px;
    }    
    .sample-link{
        cursor: pointer;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        &:hover{
            text-decoration: underline;
        }
    }
    &.authentication {
        margin: 0 .5em;
        margin-bottom: 1.5em;
        input{
            min-width: 320px;
        }
    }
`
const Label = styled.label`
    margin: 0;
    padding: 0;
`
const Input = styled.input`
    /* &[type="text"], &[type="email"], &[type="password"]{
        margin: 5px;
        &.full{
            margin: 0;
        }
        font-size: 16px;
        font-size: max(16px, 1em);
        font-family: inherit;
        padding: 0.25em 0.5em;
        background-color: #fff;
        border: 1px solid var(--bg-quad);
        border-radius: 4px;
    }  */
    background-color: #EFEFEF;
    border: 1px solid var(--bg-quad);
    border-radius: 10px;
    height: 40px;
    margin: .25em 0;
    padding: 0.25em 0.75em;
    &:focus{
        background-color: #FFF;
        border:2.5px solid var(--bg-primary);
        outline: none !important;
    }
`

export const TextBox = (props) => {
  const { label, name, sample, link, className } = props
  return (
        <Container className={className || ''}>
            <Label htmlFor={name}>{label}</Label>
            <Input {...props} type="text" name={name} />
            {sample && <span className="sample">{sample}</span>}
            {link && <span onClick={link.onClick} className="sample-link">{link.label}</span>}
        </Container>
  )
}

export const EmailBox = (props) => {
  const { label, name, sample, link, className } = props
  return (
        <Container className={className || ''}>
            <Label htmlFor={name}>{label}</Label>
            <Input {...props} type="email" name={name} />
            {sample && <span className="sample">{sample}</span>}
            {link && <span onClick={link.onClick} className="sample-link">{link.label}</span>}
        </Container>
  )
}

export const PasswordBox = (props) => {
  const { label, name, sample, link, className } = props
  return (
        <Container className={className || ''}>
            <Label htmlFor={name}>{label}</Label>
            <Input {...props} type="password" name={name} />
            {sample && <span className="sample">{sample}</span>}
            {link && <span onClick={link.onClick} className="sample-link">{link.label}</span>}
        </Container>
  )
}
