
import { combineReducers } from '@reduxjs/toolkit'

import account from '@/account/state'
import events from '@/events/state'
import general from '@/general/state'
import landing from '@/landing/state'
import post from '@/post/state'
import product from '@/product/state'

const rootReducer = combineReducers({
  account,
  events,
  general,
  landing,
  post,
  product
})

export default rootReducer
