import Link from 'next/link'
import { useRouter } from 'next/router'

import { useSelector } from 'react-redux'
import { getLandingState } from '@/landing/state'

import styled from 'styled-components'
const List = styled.li`
  a {
    text-decoration: none;
    color: black;
    &:hover {
      /* color: var(--bg-primary); */
    }
    &.active {
      font-weight: bold;
    }
    &.active-top {
      /* color: var(--bg-primary); */
      /* font-weight: 600; */
      &:hover {
        color: black;
      }
    }
  }
  @media (max-width: 640px) {
    a {
      color: black;
      &:hover {
        color: var(--bg-primary);
      }
      &.active {
        font-weight: 600;
      }
      &.active-top {
        color: black;
        font-weight: 400;
      }
    }
  }
`

type NavigationProps = {
  link: string;
  title: string;
};
type Props = {
  navigation: NavigationProps[];
};

const Navigation = ({ navigation }: Props) => {
  const { language } = useSelector(getLandingState)
  const { pathname } = useRouter()
  return (
    <div className='flex list-none gap-3'>
      {navigation.map((nav) => (
        <List>
          <Link href={`/${language}/${nav.link}`}>
            <a
              className={`
                text-sm lg:text-base 
                ${pathname === `/${language}${nav.link}` ? 'active' : 'active-top'}
              `}
            >
              {nav.title}
            </a>
          </Link>
        </List>
      ))}
    </div>
  )
}

export default Navigation
