import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import getContents from '@helper/util/landing/getContents'

import styled from 'styled-components'
const TopHeader = styled.header`
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 120px;
  background-color: white;
  &.show {
    position: fixed;
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.1);
  }
  &.hide {
    display: none;
  }
  &.left {
    position: absolute;
  }
  @media (max-width: 1225px) {
    padding: 0 1em;
  }
  @media (max-width: 600px) {
    max-width: 100vw;
    height: 80px;
    &.hide {
      display: block;
    }
  }
`
const BannerHeader = styled.section`
  min-width: 100vw;
  /* padding: .8em 0; */
  margin: 0 auto;
  background-color: darkslategrey;
  color: white;
  max-height: 550px;

  &.hide {
    display: none;
  }

  h1,
  h2,
  h3 {
    font-style: normal;
  }
  h1 {
    font-weight: bold;
    font-size: 64px;
    line-height: 96px;
    margin: 0.5em 0;
  }
  h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
  }
  h3 {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin: 0.5em 0;
  }
  @media (max-width: 600px) {
    display: none;
  }
`
const BannerContent = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: var(--lg-screen);
  height: 546px;
  padding: 2em 0;
`
const ImageLink = styled.img`
  cursor: pointer;
`

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: var(--lg-screen);
  width: 100%;
  height: 100%;
  img {
    width: 150px;
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    li {
      padding: 1em;
      a {
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        color: inherit;
        font-size: 18px;
        line-height: 27px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  &.banner {
    height: 120px;
  }
  .img-container {
    select {
      display: none;
    }
  }
  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .first {
      display: none;
    }
    .img-container {
      /* border:1px dashed black; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 1em;
      box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.1);
      img {
        width: 60px;
      }
      select {
        display: block;
      }
    }
    ul {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      li {
        padding: 0 1em;
        @media (max-width: 750px) {
          padding: 0 0.5em;
          a {
            font-size: 12px;
          }
          select {
            display: none;
          }
        }
        @media (max-width: 600px) {
          padding: 0 0.5em;
          a {
            font-size: 10px;
            &.indonesia {
              font-size: 9px;
            }
          }
          select {
            display: none;
          }
        }
      }
      /* display: none; */
    }
  }
`
const Select = styled.select`
  border: 0;
  background: transparent;
  font-size: 18px;
  margin: 0.5em 0;
  padding: 0.5em;
  padding-right: 1em;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border-radius: 5px;
    border: 1px solid var(--bg-primary);
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
`
const InverseSelect = styled.select`
  border: 0;
  background: transparent;
  color: white;
  font-size: 18px;
  margin: 0.5em 0;
  padding: 0.5em;
  padding-right: 1em;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border-radius: 5px;
    border: 1px solid var(--bg-secondary);
  }
  option {
    color: black;
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
`

const Header = () => {
  const { pathname } = useRouter()
  const { contents, language, setLanguage } = getContents()
  const { title, subtitle, navMenu } = contents
  const [hideHeader, setHideHeader] = useState(pathname !== '/')
  const [isHeaderMoved, setIsHeaderMoved] = useState(true)

  const getPathName = () => pathname.replace('/', '')

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        if (!isHeaderMoved) setIsHeaderMoved(true)
        else if (isHeaderMoved) setIsHeaderMoved(false)
      }

      if (window.pageYOffset >= 400) setHideHeader(false)
      else setHideHeader(true)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isHeaderMoved])
  const menuLabel = getPathName().replace('landing', '').replace('/', '')
  return (
    <>
      <TopHeader
        className={`${
          hideHeader && !(pathname === '/' || pathname === '/landing')
            ? 'hide'
            : hideHeader
            ? 'left'
            : 'show'
        }`}
      >
        <Navigation>
          {/* <Hamburger /> */}
          <div className="img-container">
            <Link href="./" replace>
              <ImageLink src="/media/logo/main-round.png" alt="logo" />
            </Link>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="id">Indonesia</option>
            </Select>
          </div>
          <ul>
            {navMenu.map((_menu, idx) => (
              <li key={idx} className={idx === 0 ? 'first' : ''}>
                <Link
                  href={_menu.link}
                  className={language === 'id' ? 'indonesia' : 'english'}
                >
                  {_menu.label}
                </Link>
              </li>
            ))}
            <li>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="id">Indonesia</option>
              </Select>
            </li>
          </ul>
        </Navigation>
      </TopHeader>
      <BannerHeader
        className={`${
          !(pathname === '/' || pathname === '/landing') ? 'show' : 'hide'
        }`}
        // className={`top-banner ${pathname!=="/"?"show":""}`}
        style={{
          background:
            pathname === '/'
              ? 'none'
              : `url(/media/x-banner/${encodeURIComponent(
                  getPathName().trim().replace('landing/', '')
                )}.png)`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Navigation className="container mx-auto banner">
          <Link href="./">
            <img src="/media/logo/main-inverted.png" alt="logo" />
          </Link>
          <ul>
            {navMenu.map((_menu) => (
              <li key={_menu.id}>
                <Link
                  href={_menu.link}
                  className={language === 'id' ? 'indonesia' : 'english'}
                >
                  {_menu.label}
                </Link>
              </li>
            ))}
            <li>
              <InverseSelect
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="id">Indonesia</option>
              </InverseSelect>
            </li>
          </ul>
        </Navigation>
        <BannerContent>
          <h3>Sellon/{title[menuLabel]}</h3>
          <h1>{title[menuLabel]}</h1>
          <h2>{subtitle[menuLabel]}</h2>
        </BannerContent>
      </BannerHeader>
    </>
  )
}

export default Header
