import Chat from './chat'
import Docs from './docs'
import Community from './community'
import Landing from './landing'
import Profile from './profile'

import { useSelector, useDispatch } from 'react-redux'

import { getAccountState } from '@/account/state'
import { requestLanguageResource } from '@/general/state'

import { useEffect } from 'react'

interface Props {
    children: any
    pathname: string;
}

const Layout = ({ children, pathname }: Props) => {
  const { sellonToken } = useSelector(getAccountState)
  const { apikey, nationCode } = sellonToken

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      requestLanguageResource({
        apikey,
        nation_code: nationCode
      })
    )
    return () => { }
  }, [])

  if (pathname.includes('chat')) return (<Chat>{children}</Chat>)
  else if (pathname.includes('docs')) return (<Docs>{children}</Docs>)
  else if (pathname.includes('mysellon')) return (<Profile>{children}</Profile>)
  else if (pathname.includes('en-SG') || pathname.includes('id')) return (<Community>{children}</Community>)
  else if (pathname.includes('landing')) return (<Landing>{children}</Landing>)
  else return (<>{children}</>)
}

export default Layout
