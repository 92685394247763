import SEO from "@helper/seo/head";
import Layout from "@/layout/template";

import { store, persistor } from "@store/config";

import type { AppProps } from "next/app";
import Router from "next/router";

import nProgress from "nprogress";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import { ThemeProvider } from "styled-components";

import "@style/global.scss";
// import "@style/dist/output.css";
import "@style/nprogress.scss";

// const theme = {
//   colors: {
//     primary: "#0070f3",
//   },
// };

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

const App = (props: AppProps) => {
  const { Component, pageProps, router } = props;
  const { product, shop, community, share, title } = pageProps;
  const { pathname } = router;
  return (
    <>
      <SEO title={title} product={product} shop={shop} community={community} share={share} />
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={() => localStorage.clear()}
        >
          {/* <ThemeProvider theme={theme}> */}
          <Layout pathname={pathname}>
            <Component {...pageProps} id="global" />
          </Layout>
          {/* </ThemeProvider> */}
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
