import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './initial'
import type { RootState } from '../config'

// Define the initial state using that type

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // Home Data
    requestHomeData: (state) => {
      state.homeData = { ...state.homeData, pending: true }
    },
    setHomeData: (state, action: PayloadAction[]) => {
      const {
        event_banner,
        highlight_community,
        hot_item,
        leaderboard,
        leaderboard_my_point,
        leaderboard_my_ranking
      } = action.payload
      state.homeData = {
        ...state.homeData,
        pending: false,
        eventBanner: event_banner,
        highlightPost: highlight_community,
        hotItem: hot_item,
        leaderboard: leaderboard.map(rank => ({
          id: rank.b_user_id,
          name: rank.b_user_nama,
          img: rank.b_user_image,
          posts: rank.total_post,
          points: rank.total_point
        })),
        myPoint: leaderboard_my_point,
        myRanking: leaderboard_my_ranking
      }
    },

    // Post Discovery
    requestPostDiscovery: (state) => {
      state.postDiscovery = { ...state.postDiscovery, pending: true }
    },
    setPostDiscovery: (state, action: PayloadAction[]) => {
      const {
        community_total,
        communitys
      } = action.payload
      state.postDiscovery = {
        ...state.postDiscovery,
        pending: false,
        total: community_total,
        data: communitys.map(community => {
          const {
            id, title, deskripsi, images, locations,
            c_community_category_id, community_category,
            b_user_nama_starter, b_user_image_starter, alamat4, cdate_text,
            total_likes, total_discussion
          } = community
          return ({
            id,
            title,
            labelId: c_community_category_id,
            label: community_category,
            description: deskripsi,
            user: b_user_nama_starter,
            userImg: b_user_image_starter,
            location: alamat4,
            submitDate: cdate_text,
            attachImg: images.map(img => img.url_thumb),
            attachLocation: locations,
            totalLike: total_likes,
            totalDiscussion: total_discussion
          })
        })
      }
    }
  }
})

export const {
  requestCategory, setCategory,
  requestHomeData, setHomeData,
  requestPostDiscovery, setPostDiscovery
} = postSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getPostState = (state: RootState) => state.post

export default postSlice.reducer
