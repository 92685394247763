
import Head from 'next/head'

interface Props {
    title:string;
    catchme: string;
}

const SEO = ({ title, product, shop, community, share }: Props) => {

  return (
        <Head>
            {product
              ? <ProductDetail product={product} />
              : shop
                ? <SellerShop shop={shop} />
                  : community
                  ? <CommunityDetail community={community} />
                    : share
                    ? <ShareSellon share={share} />
                      : <DefaultHead />}
            <title>{
                product? 
                  product.nama ? 
                    product.nama 
                  : 'Product'
                : shop ? 
                  shop.fnama ? 
                    shop.fnama 
                  : 'Shop'
                : community ? 
                  community.title ? 
                    community.title 
                  : 'Community' 
                : share ? 
                  share.title ? 
                    share.title 
                  : 'Share Sellon' 
                : title?
                  title
                :'Sellon | Home'
            }</title>
        </Head>
  )
}

function br2nl(string) {
  return string.replace(/<br\s*\/?>/mg,"\n");
}

export const ProductDetail = ({
  product
}: Props) => {

  var product_description = br2nl(product.deskripsi);
  var product_name = br2nl(product.nama)

  // var trimmed_product_description = product_description.substring(0, 20);
  let findSpace = product_description.indexOf(' ');
  let maxlength = 30;
  let result = product_description.substring(0, (maxlength - findSpace));
  var trimmed_product_description = result + "...";

  return (
    <>
            {/* <meta name="description" content={product.deskripsi} />
            <meta name="msapplication-TileImage" content={product.thumb} />
            <meta itemProp="name" content={product.nama} />
            <meta itemProp="image" content={product.thumb} /> */}

            <meta property="og:title" content={product_name} />
            <meta property="og:site_name" content="SellOn" />
            {/* <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + '/product_detail/' + product.nation_code + '/' + product.id} /> */}
            <meta property="og:description" content={trimmed_product_description} />
            {/* <meta property="og:type" content="website" /> */}
            <meta property="og:image" content={product.thumb} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />

            {/* <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={product.nama} />
            <meta name="twitter:image" content={product.thumb} /> */}
        </>
  )
}

export const SellerShop = ({
  shop
}: Props) => {
  return (
        <>
            {/* <meta name="description" content={shop.bio ? shop.bio : "-"} />
            <meta name="msapplication-TileImage" content={shop.image} />
            <meta itemProp="name" content={shop.fnama} />
            <meta itemProp="image" content={shop.image} /> */}

            <meta property="og:title" content={shop.fnama} />
            <meta property="og:site_name" content="SellOn" />
            {/* <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + '/shop/' + shop.nation_code + '/' + shop.id} /> */}
            <meta property="og:description" content="seller shop" />
            {/* <meta property="og:type" content="website" /> */}
            <meta property="og:image" content={shop.image} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />

            {/* <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={shop.fnama} />
            <meta name="twitter:image" content={shop.image} /> */}
        </>
  )
}

export const CommunityDetail = ({
  community
}: Props) => {

  var community_description = br2nl(community.deskripsi);
  var community_title = br2nl(community.title)

    // var trimmed_community_description = community_description.substring(0, 20);
    let findSpace = community_description.indexOf(' ');
    let maxlength = 30;
    let result = community_description.substring(0, (maxlength - findSpace));
    var trimmed_community_description = result + "...";

  return (
        <>
            {/* <meta name="description" content={community.deskripsi} /> */}
            {/* <meta name="msapplication-TileImage" content={community.images.length === 0 ? community.category_image_cover : community.images[0].url_thumb} /> */}
            {/* <meta name="msapplication-TileImage" content={community.images.length === 0 ? community.b_user_image_starter : community.images[0].url} /> */}
            {/* <meta name="msapplication-TileImage" content={community.images[0].url ? community.images[0].url : community.b_user_image_starter} /> */}
            {/* <meta itemProp="name" content={community.title} /> */}
            {/* <meta itemProp="image" content={community.images.length === 0 ? community.b_user_image_starter : community.images[0].url} /> */}
            {/* <meta itemProp="image" content={community.images.length === 0 ? community.category_image_cover : community.images[0].url_thumb} /> */}

            <meta property="og:title" content={community_title} />
            <meta property="og:site_name" content="SellOn" />
            {/* <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + '/community_detail/' + community.nation_code + '/' + community.id} /> */}
            {/* <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + community.b_user_id_starter + '/community_detail/' + community.nation_code + '/' + community.id} /> */}
            <meta property="og:description" content={trimmed_community_description} />
            {/* <meta property="og:type" content="website" /> */}
            <meta property="og:image" content={community.images.length === 0 ? community.category_image_cover : community.images[0].url_thumb} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />

            {/* <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={community.title} />
            <meta name="twitter:image" content={community.images.length === 0 ? community.category_image_cover : community.images[0].url_thumb} /> */}
        </>
  )
}

export const ShareSellon = ({
  share
}: Props) => {
  return (
        <>
            {/* <meta name="description" content="Share link with your friends and earn points" />
            <meta name="msapplication-TileImage" content="https://sellon.net/media/logo/main.png?t=12345" /> */}

            <meta name="description" content="Share link with your friends and earn points" />
            {/* <meta name="msapplication-TileImage" content="https://sellon.net/media/logo/main.png?t=12345" /> */}
            <meta itemProp="name" content="SellOn" />
            <meta itemProp="image" content="https://sellon.net/media/logo/main.png?t=12345" />

            <meta property="og:title" content="Sellon" />
            <meta property="og:site_name" content="SellOn" />
            {/* <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + '/share/' + share.code} /> */}
            <meta property="og:description" content="Share link with your friends and earn points" />
            {/* <meta property="og:type" content="website" /> */}
            <meta property="og:image" content="https://sellon.net/media/logo/main.png?t=12345" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />
        </>
  )
}

export const DefaultHead  = () => {

  return (
      <>
          <link rel="manifest" href="/manifest.json" />
          <meta httpEquiv='cache-control' content='no-cache' />
          <meta httpEquiv='expires' content='0' />
          <meta httpEquiv='pragma' content='no-cache' />
          <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
          <meta property="og:image" content="https://sellon.net/media/logo/main.png?t=12345" />
          <meta property="og:image:width" content="280" />
          <meta property="og:image:height" content="280" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Sellon" />
          <meta property="og:description" content="Commerce & Communities"/>
          <meta property="fb:app_id" content="411644339627867" />
          <meta property="og:url" content="https://sellon.net/landing" />
          <meta property="og:site_name" content="Sellon" />
          <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0" />
          <meta name="facebook-domain-verification" content="f234piqml7tupo4hc44iv02zmq9uyd" />
      </>
  )
}

export default SEO; 