import axios from 'axios'
import { urlGenerator } from '@store/api'

// axios.defaults.baseURL = 'https://cms-sgmaster.sellon.net/'
// axios.defaults.baseURL = 'https://dev2-cms-sgmaster.sellon.net/'
axios.defaults.baseURL = 'https://cms-idmaster.sellon.net/'

export function getCategory (payload) {
  const url = 'api_mobile/kategori/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}

export async function getProductDetail ({ productId, ...payload }) {
  const url = `api_mobile/produk/detail/${productId}/`
  const result = await axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return result
}

export function getProductDiscovery (payload) {
  const url = 'api_mobile/produk/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}
