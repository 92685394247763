import { all, fork, call, put } from "redux-saga/effects";

import { setApiKey } from "@/account/state";
import { getApiKey } from "@/account/state/api";

import authSaga from "@/account/state/sagas";
import eventSaga from "@/events/state/sagas";
import generalSaga from "@/general/state/sagas";
import postSaga from "@/post/state/sagas";
import productSaga from "@/product/state/sagas";

import { encryptSHA256 } from "@helper/util/useEncrypt";

export function* tokenHandler(action, callBack) {
  try {
    const { payload } = action;

    const response = yield call(callBack, payload);
    if (
      (response.data.status === 400 || response.data.status === 401) &&
      (response.data.message === "Missing or invalid API key" ||
        response.data.message === "Missing or invalid API key")
    ) {
      const apiResponse = yield call(
        getApiKey,
        payload.nationCode ? payload.nationCode : payload.nation_code
      );
      const apikey = yield encryptSHA256(apiResponse.data.data.apikey_plain);
      yield put(setApiKey({ apikey }));

      const newResponse = yield call(callBack, { ...payload, apikey });
      const { data } = yield newResponse.data;
      return yield { ...data, apikey };
    } else {
      const { data } = yield response.data;
      return yield { ...data, apikey: payload.apikey };
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(eventSaga),
    fork(generalSaga),
    fork(postSaga),
    fork(productSaga),
  ]);
}
