import styled from 'styled-components'

const Container = styled.div`
    align-items: center;
    display: flex;

    select{
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin:0;
        padding: .25em 0em;
        padding-right: .5em;

        /* min-width: 150px; */
        max-width: 100%;
        &.with-margin{
            margin:.5em;
        }
        &:hover{
            border: 1px solid var(--bg-primary);
        }
        @media (max-width:600px){
            font-size: 10px;
        }
    }

    img{
        height:18px;
        margin-right: .25em;
    }
`

function Elements (props) {
  const { icon, options } = props
  return (
        <Container>
            {icon && icon}
            {/* <img src={"media/icon_png/Location.png"} alt="" /> */}
            <select {...props}>
                {options && options.map((option, key) => (
                    <option value={option.value} key={key}>{option.label}</option>
                ))}
            </select>
        </Container>
  )
}

export default Elements
