
import getContents from '@helper/util/landing/getContents'

import styled from 'styled-components'
const Container = styled.footer`
  padding-top: 5em;
  min-height: 15em;
  background-color: #ececec;
  @media (max-width: 1200px) {
    padding: 0 1em;
    padding-top: 5em;
  }
`
const Column = styled.footer`
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: space-between;

  &.right {
    align-items: flex-end;
  }

  span {
    margin: 0.8em 0;
  }

  .footer-caption {
    /* width: 800px; */
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .footer-copyright {
    text-align: center;
    margin: 3em 0;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    margin: 0.8em 0;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-apps {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    padding: 0;
  }
  a {
    margin: 0.3em;
  }
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
`

const Footer = () => {
  const { contents } = getContents()
  const { footerCaption } = contents
  return (
    <Container>
      <Column className="container mx-auto between">
        <Left>
          <div className="footer-caption">{footerCaption}</div>
          <br />
          <div className="footer-copyright">
            {/* &copy; 2021 PT. SELLON TECH INDONESIA. ALL RIGHTS RESERVED */}
            &copy; SELLON PTE. LTD, ALL RIGHTS RESERVED
          </div>
        </Left>
        <Right className="right">
          {/* <a target="_blank" rel="noreferrer" href="/landing">
            <img
              className="footer-logo"
              src="/media/logo/main-round.png"
              alt="SellOn"
            />
          </a> */}
          {/* <img className="footer-logo" src="https://sellon.net/assets/img/logosellon.png" alt="SellOn" /> */}
          <p className='font-bold text-lg mr-2 mt-9'>Download Sellon Apps</p>
          <div className="footer-apps">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/id/app/sellon-online-marketplace/id1462394563"
            >
              <img
                width="168px"
                src="/media/footer/appstore-button.png"
                alt="appstore"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.csi.sellon"
            >
              <img
                width="168px"
                src="/media/footer/googleplay-button.png"
                alt="playstore"
              />
            </a>
          </div>
        </Right>
      </Column>
    </Container>
  )
}

export default Footer
