
import Header from '@/chat/template/header'
import Footer from '@/chat/template/footer'

import styled from 'styled-components'

import { getWindowSize } from '@helper/util/useWindowSize'

const Wrapper = styled.div`
  overflow-x: hidden;
  position: relative;
  @media (max-width: 640px) {
    padding: 0 1em;
  }
`

interface Props {
  children: any
  page: string;
}

const chat = ({
  children
}: // page,
Props) => {
  const windowSize = getWindowSize()
  return (
    <>
      {windowSize.width >= 640
        ? (
        <>
          <Header />
          <Wrapper className="container mx-auto my-5">{children}</Wrapper>
          <Footer />
        </>
          )
        : (
        <>
          <Wrapper className="w-full my-2">{children}</Wrapper>
        </>
          )}
    </>
  )
}

export default chat
