import {
  // useState,
  useEffect
} from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    gap: 1rem;
`
// const People = styled.div`
//     font-size: 14px;
//     line-height: 21px;
//     color: #333333;
// `;
const Location = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    // margin: 0 2.5em;
`

function NumberPeople ({ location, address }) {
  // const [numberOfPeople, setNumberOfPeople] = useState(1)
  useEffect(async () => {
    return () => {
    }
  }, [location])
  return (
        <Container>
            {/* <People><strong>{numberOfPeople}</strong> people around you!</People> */}
            <Location>{address}</Location>
        </Container>
  )
}

export default NumberPeople
