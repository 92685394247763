
import type { ReducerState } from './types'

// Define the initial state using that type
export const initialState: ReducerState = {
  // address: {
  //     id: ``,
  //     city: ``,
  //     country: ``,
  //     district: ``,
  //     generalLocation: ``,
  //     isDefault: 0,
  //     latitude: 0,
  //     longitude: ``,
  //     name: ``,
  //     name2: ``,
  //     note: ``,
  //     postal: 0,
  //     postalDistrict: 0,
  //     province: ``,
  //     receiverName: ``,
  //     receiverPhoneNumber: ``,
  //     status: ``,
  //     statusCode: ``,
  //     statusType: ``,
  //     subDistrict: ``,
  //     title: ``,
  //     userId: 0
  // },
  address: [],
  menu: {
    link: '',
    title: ''
  },
  error: '',
  isFollow: 0,
  isSignedIn: false,
  pending: false,
  totalCommunityPost: 0,
  totalFollower: 0,
  totalFollowing: 0,
  sellonToken: {
    apikey: '',
    apisess: '',
    nationCode: 65
  },
  userProfile: {
    banner: '',
    bio: '',
    email: '',
    firstName: '',
    id: '',
    image: '',
    lastName: '',
    website: ''
  }
}
