import { useState } from 'react'

import Register from './signup'
import SignIn from './signin'

interface Props {

}

const Authentication = (props: Props) => {
  const [menu, setMenu] = useState('Sign In')
  if (menu === 'Sign Up') return <Register switchMenu={() => setMenu('Sign In')} />
  else return <SignIn switchMenu={() => setMenu('Sign Up')} />
}

export default Authentication
