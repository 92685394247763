import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import englishContents from "./english.json";
import indonesiaContents from "./indonesia.json";
import { getLandingState, selectLanguage, setClientInfo } from "@/landing/state";

export default function getContents() {
  const dispatch = useDispatch();
  const { language } = useSelector(getLandingState);
  const setLanguage = (language) => dispatch(selectLanguage(language));

  const [contents, setContent] = useState(englishContents);
  const getContent = () => {
    return language === "id" ? indonesiaContents : englishContents;
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      let sessionLanguage = sessionStorage.getItem("lang");
      if (!sessionLanguage) {
        fetch(
          `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_PRO_IP_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            sessionLanguage = data.country === "Indonesia" ? "id" : "en-SG";
            sessionStorage.setItem("lang", sessionLanguage);
            dispatch(setClientInfo(data));
            setLanguage(sessionLanguage);
          });
      }
    }
  }, []);
  useEffect(() => setContent(getContent()), [language]);
  return { contents, language, setLanguage };
}
