
import Header from '@/layout/component/header/landing'
import Footer from '@/layout/component/footer/landing'

import styled from 'styled-components'
const Wrapper = styled.div`
    margin: 0;
    overflow: hidden;
    padding-top: 120px;
    position: relative;
    @media (max-width: 640px) { padding: 0; }
    width: 100vw;
`

interface Props {
    children: any;
}

const landing = ({ children }: Props) => {
  return (
        <>
            <Header />
            <Wrapper>
                {children}
            </Wrapper>
            <Footer />
        </>
  )
}

export default landing
