import { ReducerState } from './types'

export const initialState: ReducerState = {
  keyword: '',
  languageResource: {
    pending: false,
    data: {}
  },
  sidebarState: false,
  modalInformation: {
    error: '',
    pending: false,
    modalState: false,
    modalContent: 'This is Modal'
  },
  postalDistrict: '00',
  typeEnvironment: '',
  weatherInformation: {
    error: '',
    icon: '',
    icon_text: '',
    pending: false,
    pm2_5_text: '',
    pm2_5: 0,
    temp_c: 0
  }
}
