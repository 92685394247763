import { ReducerState } from './types'

export const initialState: ReducerState = {
  error: '',
  categories: {
    pending: false,
    data: []
  },
  productDiscovery: {
    pending: false,
    total: 0,
    data: []
  },
  productDetail: {
    pending: false,
    data: {
      descriptions: {
        origin: '',
        short: ''
      },
      id: '',
      gallery: [],
      labels: {
        address: '',
        condition: '',
        stock: 0,
        type: ''
      },
      name: '',
      price: 0,
      specs: {
        brand: '',
        category: '',
        depth: '',
        height: '',
        shipment: '',
        weight: '',
        width: ''
      }
    }
  },
  productType: 'All'
}
