import axios from 'axios'
import { urlGenerator } from '../../../store/api'
import { WeatherState, WeatherPayloadState } from '@/Store/component/constants'

// axios.defaults.baseURL = 'https://cms-sgmaster.sellon.net/'
// axios.defaults.baseURL = 'https://dev2-cms-sgmaster.sellon.net/'
axios.defaults.baseURL = 'https://cms-idmaster.sellon.net/'

export const getLanguageResource = (payload: WeatherPayloadState): Promise<WeatherState> => {
  const url = 'api_mobile/multilanguage/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}

export const getWeather = (payload: WeatherPayloadState): Promise<WeatherState> => {
  const url = 'api_mobile/homepage/weather/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}
