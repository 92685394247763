type Props = {
    label: string;
    icon: string;
    iconHover: string;
    rounded:boolean;
    shrink:boolean;
    outline:boolean;
    transparent:boolean;
    small:boolean;
    large:boolean;
    semibold:boolean;
    bold:boolean;
}

const Button = ({
  label, icon = '', iconHover = '',
  rounded = false,
  shrink = false, small = false, large = false,
  outline = false, transparent = false,
  semibold = false, bold = false
}: Props) => {
  return (
    <button
        className={`
            flex items-center gap-2
            font-normal
            ${rounded ? 'rounded-full' : 'rounded-md'}
            ${outline
? 'bg-transparent border-2 border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:border-transparent hover:text-white'
                : transparent
? 'bg-transparent border-2 border-transparent text-black'
            : 'border-2 border-transparent bg-yellow-500 text-white hover:bg-transparent hover:border-yellow-500 hover:text-yellow-500'}
            ${shrink
? 'p-1'
                : small
? 'text-xs px-3 py-1'
                : large
? 'text-lg px-5 py-3'
            : 'text-base px-5 py-1'}
            ${semibold ? 'font-semibold' : bold ? 'font-bold' : 'font-normal'} 
        `}
    >
        {icon && <img
            className={!label ? 'w-full h-full' : 'w-auto h-4/6'}
            src={icon} alt=""
        />}
        {label && <p className="m-0 p-0">
            { label }
        </p>}
    </button>
  )
}

export default Button
