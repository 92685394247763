import { getAccountState } from '@/account/state'

import Header from '@/layout/component/header/community'
import Footer from '@/layout/component/footer/community'
import MobileFooter from '@/layout/component/footer/community/mobile'

import { Circular } from '@/general/component/button/icon'
import { getWindowSize } from '@helper/util/useWindowSize'

import { useSelector } from 'react-redux'

interface Props {
  children: any;
}

const community = ({ children }: Props) => {
  const windowSize = getWindowSize()
  const { menu } = useSelector(getAccountState)
  return (
    <>
      {windowSize.width >= 640
        ? (
        <div className='bg-gray-100'>
          <Header />
          <div className="relative w-full lg:py-5">{children}</div>
          <Footer />
        </div>
          )
        : (
        <div className='bg-gray-100'>
            <div className="bg-white flex justify-between p-3">
                <Circular
                    color="#FFFFFF"
                    colorHover="#FEC010"
                    icon="/media/iconpack/arrow-left-orange.png"
                    iconHover="/media/iconpack/arrow-left-white.png"
                    mSize="20px"
                    link={menu.link}
                />
                <h1>{ menu.title }</h1>
                <div></div>
            </div>
          <div className="w-full">{children}</div>
          <MobileFooter />
        </div>
          )}
    </>
  )
}

export default community
