import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadingMenu from '@components/atom/LoadingMenu'

import { getAccountState } from '@/account/state'
import { getGeneralState, requestWeatherInformation } from '@/general/state'
import { getLandingState } from '@/landing/state'

import styled from 'styled-components'
const Container = styled.div`
  width: 250px;
  @media (max-width: 640px) {
    max-width: 200px;
  }
`
const Icon = styled.img`
  height: 24px;
  width: 24px;
`
const Degree = styled.div`
  color: #424242;
  @media (max-width: 640px) {
    font-size: 12px;
  }
`
const AirQuality = styled.div`
  text-transform: capitalize;
  color: #df721c;
  @media (max-width: 640px) {
    font-size: 12px;
  }
`

export const WeatherInformation = () => {
  const { sellonToken } = useSelector(getAccountState)
  const { weatherInformation } = useSelector(getGeneralState)
  const { proAPI } = useSelector(getLandingState)
  const { apikey, nationCode } = sellonToken
  const dispatch = useDispatch()
  useEffect(() => {
    if (proAPI.query === '') {
      fetch(
        `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_PRO_IP_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(
            requestWeatherInformation({
              apikey,
              parameter: data.query,
              nation_code: nationCode
            })
          )
        })
    } else {
      dispatch(
        requestWeatherInformation({
          apikey,
          parameter: proAPI.query,
          nation_code: nationCode
        })
      )
    }
    return () => {}
  }, [])
  if (!weatherInformation.pending) {
    return (
      <Container className="flex items-center justify-between gap-2 lg:gap-3">
        <Icon src={weatherInformation.icon} />
        <Degree className="text-sm font-bold"> {`${weatherInformation.temp_c}°`} </Degree>
        <AirQuality className="text-sm font-normal lg:font-semibold">
          {`${weatherInformation?.icon_text} : ${weatherInformation?.pm2_5_text} (${
            weatherInformation.pm2_5 &&
            parseFloat(weatherInformation.pm2_5).toFixed(2)
          })`}
        </AirQuality>
      </Container>
    )
  } else return <LoadingMenu height="100%" />
}
