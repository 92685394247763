import { all, put, takeEvery } from 'redux-saga/effects'
import {
  requestHomeData, setHomeData,
  requestPostDiscovery, setPostDiscovery
} from '.'
import { getHomeData, getPostDiscovery } from '@/post/api/request'
import { tokenHandler } from '@store/sagas'

function * requestHomeDataHandler (action) {
  try {
    const data = yield tokenHandler(action, getHomeData)
    yield put(setHomeData(data))
  } catch (error) {
    console.log(error)
  }
}
export function * requestHomeDataWatcher () {
  yield takeEvery(requestHomeData.type, requestHomeDataHandler)
}

function * requestPostDiscoveryHandler (action) {
  try {
    const data = yield tokenHandler(action, getPostDiscovery)
    yield put(setPostDiscovery(data))
  } catch (error) {
    console.log(error)
  }
}
export function * requestPostDiscoveryWatcher () {
  yield takeEvery(requestPostDiscovery.type, requestPostDiscoveryHandler)
}

export default function * rootSaga () {
  yield all([
    requestHomeDataWatcher(),
    requestPostDiscoveryWatcher()
  ])
}
