
import Sidebar from '@/layout/component/sidebar/docs'
import Header from '@/layout/component/header/docs'

interface Props {
    children: any
    page: string;
}

const docs = ({
  children
}: Props) => {
  return (
        <div className='w-screen h-screen'>
            <Header />
            <div className='flex h-full w-full'>
                <Sidebar />
                <div className='p-5 pt-24 lg:pt-10'>
                    {children}
                </div>
            </div>
        </div>
  )
}

export default docs
