import { setSidebar } from '@/general/state'
import { useDispatch } from 'react-redux'

const Header = () => {
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(setSidebar(true))

  return (
      <div className='
        lg:hidden flex items-center gap-3
        bg-white fixed top-0 w-full z-10 shadow-md px-5 py-2
      '>
          <img src="/iconpack/hamburger.png" alt="" className='cursor-pointer' onClick={toggleSidebar} />
          <img className='w-32' src="/media/logo/main.png" alt="" />
      </div>
  )
}

export default Header
