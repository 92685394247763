import Link from 'next/link'

import styled from 'styled-components'

const Container = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
`
const List = styled.li`
    margin: 0 .5em;
    a{
        text-decoration: none;
        color: black;
        &:hover{
            color: var(--bg-primary);
        }
        &.active{
            font-weight: 600;
        }
        &.active-top{
            color: var(--bg-primary);
            font-weight: 600;
            &:hover{
                color: black;
            }
        }
    }
`
function Navigation ({ top, navigation }) {
  return (
        <Container>
            {navigation.map((nav) => (
                <List>
                    <Link
                        href={`/${nav.link}`}
                    >
                        <a className={(top && nav.default && 'active-top') || (!top && nav.default && 'active')}>
                            {nav.title}
                        </a>
                    </Link>
                </List>
            ))}
        </Container>
  )
}

export default Navigation
