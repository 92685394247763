import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/config'
import { ModalState, WeatherState, LanguageState } from './types'
import { initialState } from './initial'

// Define the initial state using that type

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // Language Resource
    requestLanguageResource: (state) => {
      state.languageResouce = { ...state.languageResouce, pending: true }
    },
    setLanguageResource: (state, action: PayloadAction<LanguageState>) => {
      const { payload } = action
      state.languageResouce = { ...state.languageResouce, data: payload, pending: false }
    },

    // Weather Information
    requestWeatherInformation: (state) => {
      state.weatherInformation = { ...state.weatherInformation, pending: true }
    },
    setWeatherInformation: (state, action: PayloadAction<WeatherState>) => {
      const { payload } = action
      state.weatherInformation = { ...state.weatherInformation, ...payload, pending: false }
    },

    // Keyword
    searchKeyword: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.keyword = payload
    },

    // Environment
    selectEnvironment: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.typeEnvironment = payload
    },

    // Postal District
    setPostalDistrict: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.postalDistrict = payload
    },

    // SidebarToggle
    setSidebar: (state, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.sidebarState = payload
    },

    // Modal Information
    setModalInfo: (state, action: PayloadAction<ModalState>) => {
      state.pending = false
      const { payload } = action
      state.modalInformation = payload
    }
  }
})

export const {
  requestLanguageResource, setLanguageResource,
  requestWeatherInformation, setWeatherInformation,
  selectEnvironment, setModalInfo, setPostalDistrict, setSidebar
} = generalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getGeneralState = (state: RootState) => state.general

export default generalSlice.reducer
