import { setSidebar } from '@/general/state'

import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

type MenuProps = {
    label: string;
    href: string;
    submenu: [];
}
type Props = {
    menu: MenuProps[];
}

const navigation = ({ menu = [] }: Props) => {
  const { pathname } = useRouter()
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(setSidebar(false))
  return (
        <div className='grid content-start gap-2 overflow-auto p-5'>
            {menu.map(({ label, href, submenu }, key) => (
                <>
                    <Link key={key} href={href} passHref>
                        <a className={`
                            text-sm lg:text-base
                            hover:bg-yellow-300 hover:text-black rounded  py-2 px-3
                            ${pathname === href ? 'font-bold' : 'font-semibold'}
                            ${pathname === href ? 'bg-yellow-300 text-black' : 'bg-transparent text-white'}
                        `}>
                            { label }
                        </a>
                    </Link>
                    <div className={`
                        ${pathname.includes(href) ? 'grid' : 'hidden'}
                        content-start gap-3`
                    }>
                        {submenu?.map(({ label, href }, key) => (
                            <Link href={href} passHref>
                                <a onClick={toggleSidebar} className={`
                                    text-sm lg:text-base
                                    hover:bg-yellow-300 hover:text-black rounded  py-2 px-6
                                    ${pathname === href ? 'font-semibold' : 'font-regular'}
                                    ${pathname === href ? 'bg-yellow-300 text-black' : 'bg-transparent text-white'}
                                `}>
                                    { label }
                                </a>
                            </Link>
                        ))}
                    </div>
                </>
            ))}
        </div>
  )
}

export default navigation
