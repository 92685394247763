import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../../store/config'
import type { SellonTokenState, ProfileState, MenuState } from './types'

import { initialState } from './initial'

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    requestApiKey: (state) => {
      state.pending = true
    },
    registerUser: (state) => {
      state.pending = true
    },
    setApiKey: (state, action: PayloadAction<SellonTokenState>) => {
      const { payload } = action
      state.pending = false
      state.sellonToken.apikey = payload.apikey
    },
    setApiSession: (state, action: PayloadAction<SellonTokenState>) => {
      const { payload } = action
      state.pending = false
      state.sellonToken.apisess = payload.apisess
    },
    setMenu: (state, action: PayloadAction<MenuState>) => {
      const { payload } = action
      state.menu = payload
    },
    signInSellon: (state) => {
      state.pending = true
    },
    signInFacebook: (state) => {
      state.pending = true
    },
    signInGoogle: (state) => {
      state.pending = true
    },
    setUserAddress: (state, action: PayloadAction<ProfileState>) => {
      // const { profile, total_community_post, total_follower, total_following } = action.payload;
      state.pending = false
      state.address = action.payload
    },
    setUserProfile: (state, action: PayloadAction<ProfileState>) => {
      const { profile, total_community_post, total_follower, total_following } = action.payload

      state.pending = false
      state.isSignedIn = true

      // var { id, address_status_code, address_status, address_status_type, judul, penerima_nama, penerima_telp, alamat2, kelurahan, kecamatan, kabkota, provinsi, kodepos, negara, latitude, longitude, catatan, is_default, b_user_id, alamat4, general_location, postal_district } = address;
      // state.address = {
      //     id, latitude, longitude,
      //     city: kabkota,
      //     country: negara,
      //     district: kecamatan,
      //     generalLocation: general_location,
      //     isDefault: is_default,
      //     name: alamat2,
      //     name2: alamat4,
      //     note: catatan,
      //     postal: kodepos,
      //     postalDistrict: postal_district,
      //     province: provinsi,
      //     receiverName: penerima_nama,
      //     receiverPhoneNumber: penerima_telp,
      //     status: address_status,
      //     statusCode: address_status_code,
      //     statusType: address_status_type,
      //     subDistrict: kelurahan,
      //     title: judul,
      //     userId: b_user_id
      // };
      state.totalCommunityPost = total_community_post
      state.totalFollower = total_follower
      state.totalFollowing = total_following

      const { bio, id, email, fnama, lnama, image, image_banner, website } = profile
      state.userProfile = {
        bio,
        email,
        id,
        image,
        website,
        firstName: fnama,
        lastName: lnama,
        banner: image_banner
      }
    },
    signOut: (state) => {
      state.pending = true
    },
    setSignOut: (state) => {
      state.pending = false
      state.isSignedIn = false
      state.address = initialState.address
      state.userProfile = initialState.userProfile
    }
  }
})

export const {
  requestApiKey, setApiKey, setApiSession, setMenu,
  signInSellon, signInFacebook, signInGoogle,
  setUserProfile, setUserAddress,
  signOut, setSignOut
} = accountSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getAccountState = (state: RootState) => state.account

export default accountSlice.reducer
