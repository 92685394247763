import { all, put, takeEvery } from 'redux-saga/effects'
import {
  requestCarouselBanner, setCarouselBanner,
  requestEventBanner, setEventBanner
} from '.'
import {
  getCarouselBanner,
  getEventBanner
} from './api'
import { tokenHandler } from '../../../store/sagas'

function * requestCarouselBannerHandler (action) {
  try {
    const data = yield tokenHandler(action, getCarouselBanner)
    yield put(setCarouselBanner(data))
  } catch (error) {
    console.log(error)
  }
}

export function * requestCarouselBannerWatcher () {
  yield takeEvery(requestCarouselBanner.type, requestCarouselBannerHandler)
}

function * requestEventBannerHandler (action) {
  try {
    const data = yield tokenHandler(action, getEventBanner)

    yield put(setEventBanner(data))
  } catch (error) {
    console.log(error)
  }
}

export function * requestEventBannerWatcher () {
  yield takeEvery(requestEventBanner.type, requestEventBannerHandler)
}

export default function * rootSaga () {
  yield all([
    requestCarouselBannerWatcher(),
    requestEventBannerWatcher()
  ])
}
