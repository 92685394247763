import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: .5em;
    height: 40px;
    position: relative;
    width: 40px;
    &:hover{
        border: 1px solid gray;
    }
    &.padded{
        margin: 0 .5em;
        padding: .5em;
    }

    .tooltiptext {
        background-color: #F5F5F5;
        border-radius: 6px;
        color: #000;
        font-size:10px;
        left: 65%;
        padding: 5px 10px;
        position: absolute;
        text-align: center;
        top: 95%;
        visibility: hidden;
        z-index: 1;
    }

    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
`

function IconMenu ({ tooltip, children }) {
  return (
        <Container>
            { children }
            <span class="tooltiptext shadow-1">{tooltip}</span>
        </Container>
  )
}

export default IconMenu
