import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  signInSellon,
  signInFacebook,
  signInGoogle,
  setApiSession,
  setUserProfile,
  setUserAddress,
  signOut,
  setSignOut
} from '.'
import {
  getAuthFacebook,
  getAuthGoogle,
  getAuthSellon,
  getUserAddress,
  getUserProfile
} from './api'
import { tokenHandler } from '../../../store/sagas'
import { encryptSHA256 } from '@helper/util/useEncrypt'

function * signInSellonHandler (action) {
  try {
    const payload = yield tokenHandler(action, getAuthSellon)
    yield put(setUserProfile(payload))
  } catch (error) {
    console.log(error)
  }
}

export function * signInSellonWatcher () {
  yield takeEvery(signInSellon.type, signInSellonHandler)
}

function * signInFacebookHandler (action) {
  try {
    const { nationCode } = action.payload

    const authResponse = yield tokenHandler(action, getAuthFacebook)

    const { apisess, apikey } = yield authResponse
    yield put(setApiSession({ apisess: encryptSHA256(apisess) }))

    const response = yield call(getUserProfile, {
      apikey,
      apisess: encryptSHA256(apisess),
      nation_code: nationCode,
      b_user_id: authResponse.pelanggan.id
    })
    const { data } = response.data
    yield put(setUserProfile(data))

    const responseAddress = yield call(getUserAddress, {
      apikey,
      apisess: encryptSHA256(apisess),
      nation_code: nationCode
    })
    const dataAddress = responseAddress.data.data
    yield put(setUserAddress(dataAddress.alamat))
  } catch (error) {
    console.log(error)
  }
}

export function * signInFacebookWatcher () {
  yield takeEvery(signInFacebook.type, signInFacebookHandler)
}

function * signInGoogleHandler (action) {
  try {
    const { nationCode } = action.payload

    const authResponse = yield tokenHandler(action, getAuthGoogle)

    const { apisess, apikey } = yield authResponse
    yield put(setApiSession({ apisess: encryptSHA256(apisess) }))

    const response = yield call(getUserProfile, {
      apikey,
      apisess: encryptSHA256(apisess),
      nation_code: nationCode,
      b_user_id: authResponse.pelanggan.id
    })
    const { data } = response.data
    yield put(setUserProfile(data))

    const responseAddress = yield call(getUserAddress, {
      apikey,
      apisess: encryptSHA256(apisess),
      nation_code: nationCode
    })
    const dataAddress = responseAddress.data.data
    yield put(setUserAddress(dataAddress.alamat))
  } catch (error) {
    console.log(error)
  }
}

export function * signInGoogleWatcher () {
  yield takeEvery(signInGoogle.type, signInGoogleHandler)
}

function * signOutHandler () {
  try {
    yield put(setSignOut())
  } catch (error) {
    console.log(error)
  }
}

export function * signOutWather () {
  yield takeEvery(signOut.type, signOutHandler)
}

export default function * rootSaga () {
  yield all([
    signInSellonWatcher(),
    signInFacebookWatcher(),
    signInGoogleWatcher(),
    signOutWather()
  ])
}
