import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/config'

export type ProApiState = {
    as: string;
    city: string;
    country: string;
    countryCode: string;
    isp: string;
    lat: number;
    lon: number;
    org: string;
    query: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
};

export type ReducerState = {
    error: string;
    language: string;
    proAPI: ProApiState;
};

// Define the initial state using that type
const initialState: ReducerState = {
  error: '',
  language: 'id',
  proAPI: {
    as: '',
    city: '',
    country: '',
    countryCode: '',
    isp: '',
    lat: 0,
    lon: 0,
    org: '',
    query: '',
    region: '',
    regionName: '',
    status: '',
    timezone: '',
    zip: ''
  }
}

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    selectLanguage: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.language = payload
    },
    setClientInfo: (state, action: PayloadAction<ProApiState>) => {
      const { payload } = action
      state.proAPI = payload
    }
  }
})

export const { selectLanguage, setClientInfo } = landingSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getLandingState = (state: RootState) => state.landing

export default landingSlice.reducer
