import CryptoJS from 'crypto-js'

const secretKey = 'sellon.net'

export const encryptSHA256 = (value) => {
  const result = CryptoJS.SHA256(value)
  return result.toString()
}

export const encryptAES = (value) => {
  const result = CryptoJS.AES.encrypt(value, secretKey)
  return result.toString()
}

export const decryptAES = (value) => {
  const result = CryptoJS.AES.decrypt(value, secretKey)
  return result.toString(CryptoJS.enc.Utf8)
}
