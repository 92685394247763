import { ReducerState } from './types'

export const initialState: ReducerState = {
  error: '',
  homeData: {
    pending: false,
    hotItem: [],
    highlightPost: [],
    eventBanner: [],
    leaderboard: [],
    myRanking: 'N/A',
    myPoint: 0
  },
  postDiscovery: {
    pending: false,
    data: []
  },
  postType: 'All'
}
