import { useState } from 'react'
import styled from 'styled-components'
import useClickOutside from '@helper/util/useClickOutside'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  &.invert {
    justify-content: flex-end;
  }
`
const Header = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  /* margin: .5em 0; */
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  position: absolute;
  top: 50px;
  width: 200px;
  z-index: 100;
`
const Option = styled.li`
  padding: 0;
  .button {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;
    &:hover {
      background: #eee;
    }
  }
`

const Dropdown = ({
  children,
  items = [],
  invert = false,
  title,
  multiSelect = false,
  height = '50px'
}) => {
  const [open, setOpen] = useState(false)
  const [selection, setSelection] = useState([])
  const toggle = () => setOpen(!open)
  const ref = useClickOutside(() => {})
  const handleOnClick = (value) => {
    let payload = [...selection]
    if (payload.includes(value)) { payload = selection.filter((val) => val !== value) } else payload.push(value)
    setSelection(payload)
  }
  return (
    <Wrapper className={invert ? 'invert' : ''}>
      <Header
        tabIndex={0}
        role="button"
        onKeyPress={toggle}
        onClick={toggle}
        ref={ref}
      >
        {children}
      </Header>
      {open && (
        <List className="shadow-1">
          {items.map((item) => (
            <Option key={item.id}>
              <button
                className="button"
                type="button"
                onClick={
                  item.onClick ? item.onClick : () => handleOnClick(item.id)
                }
              >
                <span>{item.label}</span>
                <strong>{selection.includes(item.id) ? 'Selected' : ''}</strong>
              </button>
            </Option>
          ))}
        </List>
      )}
    </Wrapper>
  )
}
export default Dropdown
