import { useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`
const Loader = styled.div`
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border:5px solid #F3F3F3;
    border-radius: 50%;
    border-top:5px solid #AAAAAA;
    height: 100%;
    width: 100%;
`

interface Props {
    height: string;
    width: string;
}
const Element = ({ height, width }:Props) => {
  const ref = useRef(null)
  return (
        <Container
            ref={ref}
            style={{
              height: height || (width ? ref.current && ref.current.offsetWidth : '50px'),
              width: width || (height ? ref.current && ref.current.offsetHeight : '50px')
            }}
        >
            <Loader
                // style={{
                //     border: `${ref.current && ref.current.offsetWidth/5}px solid #f3f3f3`,
                //     borderTop: `${ref.current && ref.current.offsetWidth/5}px solid #AAA`
                // }}
            />
        </Container>
  )
}

export default Element
