
import styled from 'styled-components'
import Newsletter from '@/layout/component/footer/newsletter'

const Container = styled.div`
    border-top: 2px solid #ECECEC;
    overflow-x:hidden; 
    /* background-color: #ECECEC; */
`
export const Column = styled.div`
    display: flex;
    flex-direction: row;
`
const Light = styled.div`
    padding: 2.5rem 0;
`
const Dark = styled.div`
    padding: .5rem 0;
    background-color: #000;
    color: #ECECEC;
`
const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
`
const MobileIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 350px;
`
const RowLink = styled.div`
    display: flex;
    flex-direction: column;
    width: 165px;
    &.wide{
       width: 200px;
    }
    a{
        color: #555;
        margin: .75em 0;
        text-decoration: none;
        font-size: 14px;
    }
`
const SocialMedia = styled.a`
    height: 44px;
    text-decoration: none;
    width: 44px;
    background: #E4E4E4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1em;
    img{

    }
`
function Footer () {
  return (
        <Container>
            <Light>
                <div className="container mx-auto">
                    <div className="flex justify-between">
                        <div>
                            <Title>SellOn</Title>
                            <Column className="between">
                                <RowLink>
                                    <a href="#">About SellOn</a>
                                    <a href="#">Rules of Use</a>
                                    <a href="#">Privacy Policy</a>
                                    <a href="#">Career at SellOn</a>
                                    <a href="#">Vulnerability Reports</a>
                                    <a href="#">SellOn Blog</a>
                                    <a href="#">FAQ</a>
                                </RowLink>
                            </Column>
                        </div>
                        <div>
                            <Title>Buyer</Title>
                            <Column className="between">
                                <RowLink>
                                    <a href="#">How to Shop</a>
                                    <a href="#">Payment</a>
                                    <a href="#">Promo</a>
                                    <a href="#">Tag Pages</a>
                                    <a href="#">Procurement Service</a>
                                    <a href="#">Review</a>
                                </RowLink>
                            </Column>
                        </div>
                        <div>
                            <Title>Seller</Title>
                            <Column className="between">
                                <RowLink className="wide">
                                    <a href="#">How to Sell</a>
                                    <a href="#">Selling Advantages</a>
                                    <a href="#">Brand Index</a>
                                </RowLink>
                            </Column>
                        </div>
                        <div>
                            <Title>Categories</Title>
                            <Column className="between">
                                <RowLink className="wide">
                                    <a href="#">Free Product</a>
                                    <a href="#">Sports & Outdoors</a>
                                    <a href="#">Health</a>
                                    <a href="#">Books</a>
                                    <a href="#">Computers</a>
                                    <a href="#">Furniture</a>
                                </RowLink>
                                <RowLink className="wide">
                                    <a href="#">Automotive</a>
                                    <a href="#">Pet Supplies</a>
                                    <a href="#">Men’s Fashion</a>
                                    <a href="#">Women’s Fashion</a>
                                    <a href="#">Home Appliances</a>
                                    <a href="#">Baby & Kids</a>
                                </RowLink>
                                <RowLink className="wide">
                                    <a href="#">Beauty & Personal Care</a>
                                    <a href="#">Groceries</a>
                                    <a href="#">Luxury Products</a>
                                    <a href="#">Phone & Tablet</a>
                                    <a href="#">Accessories</a>
                                    <a href="#">Car Accessories</a>
                                </RowLink>
                                <RowLink className="wide">
                                    <a href="#">Toys & Games</a>
                                    <a href="#">Electronics</a>
                                    <a href="#">Arts</a>
                                </RowLink>
                            </Column>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <Newsletter />
                        <div>
                            <Title>Follow Us</Title>
                            <Column className="between">
                                <SocialMedia>
                                    <img src="media/logo/vector-whatsapp.png" alt="Whatsapp" />
                                </SocialMedia>
                                <SocialMedia>
                                    <img src="media/logo/vector-facebook.png" alt="Facebook" />
                                </SocialMedia>
                                <SocialMedia>
                                    <img src="media/logo/vector-instagram.png" alt="Instagram" />
                                </SocialMedia>
                                <SocialMedia>
                                    <img src="media/logo/vector-linkedin.png" alt="LinkedIn" />
                                </SocialMedia>
                            </Column>
                        </div>
                        <div>
                            <Title>Download SellOn Apps</Title>
                            <MobileIcon>
                                <img src="/media/footer/googleplay-button.png" alt="" />
                                <img src="/media/footer/appstore-button.png" alt="" />
                            </MobileIcon>
                        </div>
                    </div>
                </div>
            </Light>
            <Dark>
                <div className="container mx-auto">
                    Test
                </div>
            </Dark>
        </Container>
  )
}

export default Footer
