
import { useEffect, useRef, useState } from "react";
import { getWindowSize } from "@helper/util/useWindowSize";

type Props = {}
export const OutlineIcon = ({
  color,
  colorHover,
  icon,
  iconHover,
  mColor,
  mIcon,
  label,
  onClick,
  size = "35px",
}: Props) => {
  const windowSize = getWindowSize();
  const [hovered, setHovered] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const mouseOver = () => setHovered(true);
    const mouseOut = () => setHovered(false);
    ref.current && ref.current.addEventListener("mouseover", mouseOver);
    ref.current && ref.current.addEventListener("mouseout", mouseOut);
    return () => {
      ref.current && ref.current.removeEventListener("mouseover", mouseOver);
      ref.current && ref.current.removeEventListener("mouseout", mouseOut);
    };
  }, []);
  return (
    <div  
      className="flex items-center rounded cursor-pointer"
      style={{
        background: !hovered
          ? windowSize.width >= 640
            ? color
            : mColor || color
          : colorHover || "#EEEEEE",
        border: `2px solid ${
          hovered
            ? windowSize.width >= 640
              ? color
              : mColor || color
            : colorHover || "#EEEEEE"
        }`,
        color: `2px solid ${
          hovered
            ? windowSize.width >= 640
              ? color
              : mColor || color
            : colorHover || "#EEEEEE"
        }`,
        height: size,
        justifyContent: icon ? "space-evenly" : "center",
        // width: size
      }}
      onClick={onClick}
      ref={ref}
    >
      {!hovered && (
        <img alt="" src={windowSize.width >= 640 ? icon : mIcon || icon} />
      )}
      {hovered && iconHover && <img alt="" src={iconHover} />}
      {label}
    </div>
  );
};


const OutlineButton = (props: Props) => {
  return (
    <button
        className="btn-outlined py-2 px-10 rounded-3xl"
        {...props}
    >
        { props.children }
    </button>
  )
}

export default OutlineButton
