import axios from 'axios'
import { urlGenerator } from '../../../store/api'

// axios.defaults.baseURL = 'https://cms-sgmaster.sellon.net/'
// axios.defaults.baseURL = 'https://dev2-cms-sgmaster.sellon.net/'
axios.defaults.baseURL = 'https://cms-idmaster.sellon.net/'

const username = 'mobile'
const password = 'a4786851f5261822f0f57182ee2dc656b57687c8a105c1b697c280ef48aee7e8'

export function getApiKey (nationCode) {
  const data = new FormData()
  data.append('username', username)
  data.append('password', password)
  return axios.request({
    method: 'post',
    url: `api_mobile/apikey/auth/?nation_code=${nationCode}`,
    data
  })
}

export function getAuthSellon (payload) {
  const url = 'api_mobile/pelanggan/login/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}

export function getAuthFacebook ({ apikey, email, id, nationCode }) {
  const data = new FormData()
  data.append('email', email)
  data.append('fb_id', id)
  return axios.request({
    method: 'post',
    url: `api_mobile/pelanggan/login_sosmed/?apikey=${apikey}&nation_code=${nationCode}`,
    data
  })
}

export function getAuthGoogle ({ apikey, email, id, nationCode }) {
  const data = new FormData()
  data.append('email', email)
  data.append('google_id', id)
  return axios.request({
    method: 'post',
    url: `api_mobile/pelanggan/login_sosmed/?apikey=${apikey}&nation_code=${nationCode}`,
    data
  })
}

export function getUserProfile (payload) {
  const url = 'api_mobile/profile/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}

export function getUserAddress (payload) {
  const url = 'api_mobile/pelanggan/alamat/'
  const requestAPI = axios.request({
    url: urlGenerator(url, payload),
    method: 'get'
  })
  return requestAPI
}
