import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './initial'
import type { RootState } from '../config'

// Define the initial state using that type

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // Category
    requestCategory: (state) => {
      state.categories = { ...state.categories, pending: true }
    },
    setCategory: (state, action: PayloadAction[]) => {
      const { payload } = action
      state.categories = { ...state.categories, pending: false, data: payload.kategori }
    },

    // Discovery
    requestProductDiscovery: (state) => {
      state.productDiscovery = { ...state.productDiscovery, pending: true }
    },
    requestMoreProductDiscovery: (state) => {
      state.productDiscovery = { ...state.productDiscovery, pending: true }
      console.log('more')
    },
    setProductDiscovery: (state, action: PayloadAction[]) => {
      const { payload } = action
      const { more, produk_total, produks } = payload
      if (more) {
        state.productDiscovery = {
          ...state.productDiscovery,
          pending: false,
          total: state.productDiscovery.total + produk_total,
          data: [...state.productDiscovery.data, produks]
        }
      } else {
        state.productDiscovery = {
          ...state.productDiscovery,
          pending: false,
          total: produk_total,
          data: produks
        }
      }
    },

    // Detail
    requestProductDetail: (state) => {
      state.productDetail = { ...state.productDetail, pending: true }
    },
    setProductDetail: (state, action: PayloadAction[]) => {
      // const { b_kategori_id, b_user_id_seller, b_user_alamat_id_seller, b_user_fnama_seller, deskripsi, deskripsi_singkat, id, telp, email, b_user_image_seller, b_kondisi_id, b_kondisi_nama, b_kondisi_icon, b_berat_id, b_berat_nama, b_berat_icon, kategori, kategori_icon, c_produk_detail_automotive_model, c_produk_detail_automotive_color, c_produk_detail_automotive_year, nama, total_discussion, total_likes, brand, harga_jual, berat, dimension_long, dimension_width, dimension_height, stok, satuan, foto, thumb, vehicle_types, courier_services, services_duration, alamat2, alamat4, latitude, longitude, provinsi, kabkota, kecamatan, kelurahan, kodepos, negara, is_include_delivery_cost, is_published, is_visible, is_active, is_liked, is_sold_out, product_type, galeri, is_wishlist, diskusi_total, diskusis, } = action.payload;
      const {
        alamat4,
        b_kondisi_nama,
        berat,
        brand,
        courier_services,
        deskripsi, deskripsi_singkat,
        dimension_long, dimension_width, dimension_height,
        galeri,
        harga_jual,
        id,
        kategori,
        nama,
        product_type,
        stok
      } = action.payload
      const data = {
        descriptions: {
          origin: deskripsi,
          short: deskripsi_singkat
        },
        id,
        gallery: galeri.map((_gallery) => ({
          id: _gallery.c_produk_id,
          image: _gallery.url,
          thumb: _gallery.url_thumb
        })),
        labels: {
          address: alamat4,
          condition: b_kondisi_nama,
          stock: stok,
          type: product_type
        },
        name: nama,
        price: harga_jual,
        specs: product_type === 'Protection'
          ? {
              brand,
              category: kategori,
              depth: dimension_long,
              height: dimension_height,
              shipment: courier_services,
              weight: berat,
              width: dimension_width
            }
          : product_type === 'Automotive'
            ? {
                brand,
                category: kategori,
                depth: dimension_long
              }
            : { category: kategori }
      }
      state.productDetail = { ...state.productDetail, pending: false, data }
    }
  }
})

export const {
  requestCategory, setCategory,
  requestProductDetail, setProductDetail,
  requestProductDiscovery, requestMoreProductDiscovery, setProductDiscovery
} = productSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getProductState = (state: RootState) => state.product

export default productSlice.reducer
