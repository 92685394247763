import { all, put, takeEvery } from 'redux-saga/effects'
import {
  requestCategory, setCategory,
  requestProductDetail, setProductDetail,
  requestProductDiscovery, requestMoreProductDiscovery, setProductDiscovery
} from '.'
import {
  getCategory,
  getProductDetail,
  getProductDiscovery
} from '@/product/api/request'
import { tokenHandler } from '@store/sagas'

function * requestCategoryHandler (action) {
  try {
    const data = yield tokenHandler(action, getCategory)
    yield put(setCategory(data))
  } catch (error) {
    console.log(error)
  }
}

export function * requestCategoryWatcher () {
  yield takeEvery(requestCategory.type, requestCategoryHandler)
}

function * requestProductDiscoveryHandler (action) {
  try {
    const data = yield tokenHandler(action, getProductDiscovery)
    yield put(setProductDiscovery({ ...data, more: false }))
  } catch (error) {
    console.log(error)
  }
}

export function * requestProductDiscoveryWatcher () {
  yield takeEvery(requestProductDiscovery.type, requestProductDiscoveryHandler)
}

function * requestMoreProductDiscoveryHandler (action) {
  try {
    const data = yield tokenHandler(action, getProductDiscovery)
    yield put(setProductDiscovery({ ...data, more: true }))
  } catch (error) {
    console.log(error)
  }
}

export function * requestMoreProductDiscoveryWatcher () {
  yield takeEvery(requestMoreProductDiscovery.type, requestMoreProductDiscoveryHandler)
}

function * requestProductDetailHandler (action) {
  try {
    const { produk } = yield tokenHandler(action, getProductDetail)
    yield put(setProductDetail(produk))
  } catch (error) {
    console.log(error)
  }
}

export function * requestProductDetailWatcher () {
  yield takeEvery(requestProductDetail.type, requestProductDetailHandler)
}

export default function * rootSaga () {
  yield all([
    requestCategoryWatcher(),
    requestProductDetailWatcher(),
    requestProductDiscoveryWatcher(),
    requestMoreProductDiscoveryWatcher()
  ])
}
