import { ReducerState } from './types'

export const initialState: ReducerState = {
  carouselBanner: {
    error: '',
    pending: false,
    data: []
  },
  eventBanner: {
    error: '',
    pending: false,
    data: []
  }
}
