import { getGeneralState, setSidebar } from '@/general/state'
import { useSelector, useDispatch } from 'react-redux'

const sidebar = () => {
  const { sidebarState } = useSelector(getGeneralState)
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(setSidebar(false))
  return (
    <div
        style={{ background: 'rgba(0,0,0,.5)' }}
        className={`
            absolute ${sidebarState ? 'visible' : 'invisible'}
            ${sidebarState ? 'opacity-100' : 'opacity-0'}
            h-full w-full z-50
            top-0 left-0 shadow-2xl
            transition ease-in-out duration-200
        `}
    >
        <div className={`
            bg-white h-full 
            ${sidebarState ? 'w-5/6' : 'w-0'}
            transition ease-in-out duration-200
        `}>
            <div className="
                border-b
                grid justify-items-center gap-3
                p-5
            ">
                <div className="flex w-full justify-end">
                    <img className="cursor-pointer" onClick={toggleSidebar} src="/menu/Add.png" />
                </div>
                <div className="
                    overflow-hidden rounded-full
                    h-20 w-20
                    justify-self-center
                ">
                    <img className="h-full w-full object-cover" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP_xq0w_t9VLc2FZgHUe90J1kHLE-srD2eMw&usqp=CAU" alt="" />
                </div>
                <h2 className="font-bold">Crishtian Didik Maryono</h2>
                <p className="text-xs">18 EUNOS ROAD 2</p>
                <div className="w-full flex gap-3">
                    <button className="w-full rounded-lg py-2 text-xs">Edit Profile</button>
                    <button className="w-full rounded-lg py-2 text-xs">Change Password</button>
                </div>
            </div>
            <div className="
                border-b
                grid justify-items-center gap-3
                p-5
            ">
                <h2 className="font-semibold text-left w-full">Automotive</h2>
                <ul className="grid gap-3 w-full">
                    <li>
                        <a className="text-left ml-5" href="">Car</a>
                    </li>
                </ul>
            </div>
            <div className="
                border-b
                grid justify-items-center gap-3
                p-5
            ">
                <h2 className="font-semibold text-left w-full">Buy & Sell</h2>
                <ul className="grid gap-3 w-full">
                    <li>
                        <a className="text-left ml-5" href="">Wishlist</a>
                    </li>
                </ul>
            </div>
            <div className="
                border-b
                grid justify-items-center gap-3
                p-5 pb-28
            ">
                <h2 className="font-semibold text-left w-full">General</h2>
                <ul className="grid gap-3 w-full">
                    <li> <a className="text-left ml-5" href="">
                        Term & Conditions
                    </a> </li>
                    <li> <a className="text-left ml-5" href="">
                        FAQs
                    </a> </li>
                </ul>
            </div>
            <div className="
                grid justify-items-center gap-3
                p-5
            ">
                <div className="w-full flex gap-3">
                    <button className="w-full rounded-lg">Sign Out</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default sidebar
