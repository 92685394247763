
import SolidButton from '@/general/component/button/solid'
import { EmailBox, PasswordBox, TextBox } from '@/general/component/input'
// import actions from '@/Redux/actions'

import styled from 'styled-components'
export const Column = styled.div`
    display: flex;
`
const Wrapper = styled.div`
    background: white;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    min-height: 713px;
    min-width: 782px;
    padding: 2em;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
`
const Subtitle = styled.p`
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 1em;
`
const Separator = styled.div`
    margin: .75em 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #747474;
    max-width: 782px;
    word-wrap: break-word;
    &.text-left{
        text-align: left;
    }
`
const Closing = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin: 1em 0;
    a{
        font-weight: 300;
    }
`
interface Props { switchMenu:void }

const SignUp = ({ switchMenu }: Props) => {
  return (
        <Wrapper>
            <Title>Sign Up</Title>
            <Subtitle>Sign up now to explore SellOn!</Subtitle>
            <Form autocomplet="off">
                <Column>
                    <TextBox className="authentication" label="Full Name" name="fullname" />
                    <EmailBox className="authentication" label="Email" name="email" sample="" />
                </Column>
                <Column>
                    <TextBox className="authentication" label="Phone Number" name="phone" />
                    <TextBox className="authentication" label="Address Location" name="address" sample="" />
                </Column>
                <Column>
                    <PasswordBox className="authentication" label="Password" name="password" sample="*8-12 characters with at least one numeric character" />
                    <PasswordBox className="authentication" label="Confirm Password" name="confirm_password" sample="" />
                </Column>
                <Separator className="text-left">
                    We verify identities via mobile numbers to ensure a safe community for you to deal in. An SMS will be sent to you. Message and data rates may apply.
                </Separator>
                <SolidButton className="authentication">Sign Up</SolidButton>
            </Form>
            <Closing>
                don’t have SellOn account?  <a href="" onClick={(e) => { e.preventDefault(); switchMenu() } }>Sign In</a>
            </Closing>
        </Wrapper>
  )
}

export default SignUp
