import { Circular } from '@/general/component/button/icon'

import styled from 'styled-components'
const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Column = styled.div`
    display: flex;
    justify-content: space-between;
`
const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 1rem;
`
const Subtitle = styled.div`
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 1rem;
`
const TextArea = styled.input`
    background: #EDEDED;
    border: transparent;
    border-radius: 20px;
    height: 48px;
    padding: 1em 2em;
    width: 85%;
`

function Newsletter () {
  return (
        <Container>
            <Title>Newsletter Subscription</Title>
            <Subtitle>Receive product, news and update in your inbox.</Subtitle>
            <Column>
                <TextArea type="text" placeholder="Email Address" />
                {/* <Button><img src="/media/icon_png/Arrow_Right.png" alt="" /></Button> */}
                <Circular
                    color='#000000'
                    icon='/media/iconpack/arrow-right-white.png'
                    iconHover='/media/iconpack/arrow-right.png'
                    size="50px"
                />
            </Column>
        </Container>
  )
}

export default Newsletter
