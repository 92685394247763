interface Props {
    value: string
}

export const sentenceCase = (value: Props) => {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
export const getFirstName = (payload: string) => {
  const result = payload ? payload.split(' ') : ''
  return result[0]
}
