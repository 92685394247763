import Link from 'next/link'

import { useDispatch, useSelector } from 'react-redux'

import Navigation from './navigation'

import Button from '@/general/component/button'
import Select from '@components/atom/Select'
import Menu from '@components/atom/IconMenu'
import Dropdown from '@/general/component/dropdown/menu'

import Authentication from '@/account/template'
import NumberPeople from '@/layout/component/header/peopleNumber'
import { WeatherInformation } from '@/layout/component/header/community/weather'

import { getAccountState, signOut } from '@/account/state'
import { getLandingState, selectLanguage } from '@/landing/state'
import { getGeneralState, selectEnvironment } from '@/general/state'

import useModal from '@helper/util/useModal'
import { getFirstName } from '@helper/util/useCase'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100vw;
`
const Top = styled.div`
  background: #fff;
  // background: linear-gradient(270deg, #FFE5CF 5.98%, #FFFFFF 84.07%);
  padding: 0.5rem 0;
  width: 100%;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
const GnB = styled.div`
  align-items: center;
  background-color: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  /* padding: 0 5em; */
  height: 95px;
  width: 100%;
  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`
const Column = styled.div`
  align-items: center;
  display: flex;
  // justify-content: space-between;
  // .padded{
  //     margin: 0 .5em;
  // }
`

const UserAccount = styled.div`
  display: flex;
  align-items: center;
  img {
    margin: 0 0.5em;
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
`

const Header = () => {
  const dispatch = useDispatch()
  const { callModal } = useModal()

  const { address, isSignedIn, userProfile } = useSelector(getAccountState)
  const { language } = useSelector(getLandingState)
  const { typeEnvironment } = useSelector(getGeneralState)

  const { firstName, image } = userProfile

  const setLanguage = (lang) => dispatch(selectLanguage(lang))
  const setEnvironment = (lang) => dispatch(selectEnvironment(lang))

  return (
    <Container className="sticky top-0 z-10 border">
      <Top>
        <div className="container mx-auto">
          <Column>
            <NumberPeople
              location=""
              address="#03-01, 62, UBI ROAD 1, OXLEY BIZHUB 2"
            />
          </Column>
          <Column>
            <WeatherInformation />
            <div>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                options={[
                  { label: 'English', value: 'en' },
                  { label: 'Indonesia', value: 'id' }
                ]}
              />
            </div>
          </Column>
        </div>
      </Top>
      <GnB>
        <div className="container mx-auto">
          <div className='flex items-center gap-3'>
            <Menu tooltip="Menu" className="padded">
              <img src="/menu/Hamburger.png" />
            </Menu>
            <div className="padded">
              <Link href="/" passHref>
                <a href="">
                  <img src="/media/logo/main-round.png" alt="" />
                </a>
              </Link>
            </div>
            <Select
              disabled={!address || address.length === 0}
              icon={<img src="/media/icon_png/Location.png" alt="" />}
              options={
                address && address.length > 0
                  ? address.map((_address) => ({
                    label: _address.general_location,
                    value: _address.id
                  }))
                  : [{ label: 'Location', value: '' }]
              }
            />
            <Select
              className="with-margin"
              options={[
                { label: 'All', value: '' },
                { label: 'Same Building & Street', value: 'sameStreet' },
                { label: 'Neighborhoods', value: 'neighborhood' }
              ]}
              onClick={
                !isSignedIn
                  ? (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      callModal(<Authentication />)
                    }
                  : null
              }
              onChange={(e) => setEnvironment(e.target.value)}
              value={typeEnvironment}
            />
          </div>
          <Column>
            <div className="padded">
              <Navigation
                navigation={[
                  { title: 'Home', link: '' },
                  { title: 'Buy & Sell', link: '/buyandsell' },
                  { title: 'Around', link: '/around' }
                  // { title: 'My Sellon', link: '/mysellon' }
                ]}
              />
            </div>
            <Menu tooltip="Add" className="padded">
              <img src="/menu/Add.png" />
            </Menu>
            <Menu tooltip="Category" className="padded">
              <img src="/menu/Category.png" />
            </Menu>
            <Menu tooltip="Notification" className="padded">
              <img src="/menu/Notification.png" />
            </Menu>
            <Menu tooltip="Chat" className="padded">
              <Link href="/id/chat">
                <img src="/menu/Chat.png" />
              </Link>
            </Menu>
            <div>
              {!isSignedIn
                ? (
                <Button
                  label="Sign In"
                  onClick={() => callModal(<Authentication />)}
                />
                  )
                : (
                <Dropdown
                  invert
                  items={[
                    {
                      id: 2,
                      label: 'Sign Out',
                      onClick: () => dispatch(signOut())
                    }
                  ]}
                >
                  <UserAccount>
                    Hi,&nbsp;<strong>{getFirstName(firstName)}</strong>{' '}
                    <img src={image} alt="" />
                  </UserAccount>
                </Dropdown>
                  )}
            </div>
          </Column>
        </div>
      </GnB>
    </Container>
  )
}

export default Header
