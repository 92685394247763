import { all, put, takeEvery } from 'redux-saga/effects'
import {
  requestLanguageResource, setLanguageResource,
  requestWeatherInformation, setWeatherInformation
} from '.'
import { getLanguageResource, getWeather } from './api'
import { tokenHandler } from '../../../store/sagas'

function * requestLanguageResourceHandler (action) {
  try {
    const data = yield tokenHandler(action, getLanguageResource)
    yield put(setLanguageResource(data))
  } catch (error) {
    console.log(error)
  }
}

export function * requestLanguageResourceWatcher () {
  yield takeEvery(requestLanguageResource.type, requestLanguageResourceHandler)
}

function * requestWeatherInformationHandler (action) {
  try {
    const data = yield tokenHandler(action, getWeather)
    yield put(setWeatherInformation(data))
  } catch (error) {
    console.log(error)
  }
}

export function * requestWeatherInformationWatcher () {
  yield takeEvery(requestWeatherInformation.type, requestWeatherInformationHandler)
}

export default function * rootSaga () {
  yield all([
    requestLanguageResourceWatcher(),
    requestWeatherInformationWatcher()
  ])
}
