import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SolidButton from '@/general/component/button/solid'
import { EmailBox, PasswordBox } from '@/general/component/input'

import SocialButton from '@components/atom/SocialButton'

import { getAccountState, signInFacebook, signInGoogle } from '@/account/state'

import useModal from '@helper/util/useModal'
import { validateEmail } from '@helper/util/validation'

import styled from 'styled-components'
const Wrapper = styled.div`
  background: white;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  min-height: 560px;
  max-height: 715px;
  justify-content: center;
  margin: 0 auto;
  padding: 2em;
  min-width: 420px;
  max-width: 782px;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
`
const Subtitle = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1em;
`
const GridColumn = styled.div`
  display: grid;
  column-gap: 1em;
  grid-template-areas: "a a";
  padding: 1em 0;
`
const Separator = styled.div`
  margin: 0.75em 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #747474;
  word-wrap: break-word;
  &.text-left {
    text-align: left;
  }
`
const Closing = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin: 1em 0;
  a {
    font-weight: 300;
  }
`
interface Props {}

const SignIn = ({ switchMenu }: Props) => {
  const { callModal, closeModal } = useModal()
  const dispatch = useDispatch()
  const { sellonToken } = useSelector(getAccountState)

  const { apikey, nationCode } = sellonToken

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleSignIn = async (event) => {
    event.preventDefault()
    if (email && password) {
      if (!validateEmail(email)) alert('Format Email salah')
      else {
        // dispatch(requestUserProfile({
        //     apikey,
        //     nation_code: nationCode,
        //     apisess,
        //     b_user_id: 5985
        // }));
      }
    } else callModal('Email atau Password kosong')
  }

  const handleGoogleSignIn = ({ _profile }) => {
    const { email, id } = _profile
    dispatch(signInGoogle({ apikey, email, id, nationCode }))
    closeModal()
  }

  const handleFacebookSignIn = ({ _profile }) => {
    const { id, email } = _profile
    dispatch(signInFacebook({ apikey, email, id, nationCode }))
    closeModal()
  }

  const handleSignInFailure = (err) => {
    console.error(err)
    closeModal()
  }

  return (
    <Wrapper>
      <Title>Sign In</Title>
      <Subtitle> &nbsp; </Subtitle>
      <Form>
        <EmailBox
          className="full"
          label="Email"
          name="email"
          sample="Example: sellon@outlook.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordBox
          label="Password"
          name="password"
          link={{
            label: 'Forgot password?',
            onClick: () => alert('Open Forgotten Password')
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SolidButton onClick={handleSignIn}>Sign In</SolidButton>
      </Form>
      <Separator>or login with</Separator>

      <GridColumn>
        <SocialButton
          provider="facebook"
          // appId="283513250404223"
          // appId="201773895495247"
          appId="3130242350580089"
          onLoginSuccess={handleFacebookSignIn}
          onLoginFailure={handleSignInFailure}
          style={{ background: 'var(--blue)', color: 'white' }}
        >
          Facebook
        </SocialButton>
        <SocialButton
          provider="google"
          appId="765416794635-06hfn7opj56kbuv8v9nlj6cjb4klepog.apps.googleusercontent.com"
          onLoginSuccess={handleGoogleSignIn}
          onLoginFailure={handleSignInFailure}
          style={{ background: 'var(--red)', color: 'white' }}
        >
          Google
        </SocialButton>
        {/* <SolidButton
                    onClick={loginWithFacebook}
                >
                    Facebook
                </SolidButton>
                <SolidButton
                    onClick={loginWithGoogle}
                    style={{ background: 'var(--red)', }}
                >
                    Google
                </SolidButton> */}
      </GridColumn>
      <Closing>
        don’t have SellOn account?{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            switchMenu()
          }}
        >
          Sign Up
        </a>
      </Closing>
    </Wrapper>
  )
}

export default SignIn
