import { useDispatch, useSelector } from "react-redux";
import { getGeneralState, setModalInfo } from "@/general/state";

const useModal = () => {
  const { modalInformation } = useSelector(getGeneralState);
  const { modalState, modalContent } = modalInformation;

  const dispatch = useDispatch();
  const callModal = (modalContent) =>
    dispatch(setModalInfo({ modalState: true, modalContent }));
  const closeModal = () =>
    dispatch(setModalInfo({ modalState: false, modalContent: "" }));
  return {
    modalState,
    modalContent,
    callModal,
    closeModal,
  };
};

export default useModal;
