import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'

import { getWindowSize } from '@helper/util/useWindowSize'

import styled from 'styled-components'
export const CircleContainer = styled.div`
  align-items: center;
  background: ${(props) => props.color || '#FEC010'};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background: ${(props) => props.hover || '#EEE'};
  }
`
export const RoundContainer = styled.div`
  align-items: center;
  border-radius: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

interface Props {
  color: string;
  colorHover: string;
  mColor: string;
  mColorHover: string;
  icon: string;
  iconHover: string;
  link: string;
  mIcon: string;
  mIconHover: string;
  size: string;
  mSize: string;
}

export const Circular = ({
  color, colorHover,
  icon, iconHover,
  link, onClick,
  mColor, mIcon, mSize = '35px',
  padding = '5px', size = '35px'
}: Props) => {
  const windowSize = getWindowSize()
  const [hovered, setHovered] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const mouseOver = () => setHovered(true)
    const mouseOut = () => setHovered(false)
    ref.current && ref.current.addEventListener('mouseover', mouseOver)
    ref.current && ref.current.addEventListener('mouseout', mouseOut)
    return () => {
      ref.current && ref.current.removeEventListener('mouseover', mouseOver)
      ref.current && ref.current.removeEventListener('mouseout', mouseOut)
    }
  }, [])
  if (link) {
    return (
    <Link href={link}>
      <CircleContainer
        style={{
          background: !hovered
            ? windowSize.width >= 640
              ? color
              : mColor || color
            : colorHover || '#EEEEEE',
          padding: padding || 0,
          height: windowSize.width >= 640 ? size : mSize || size,
          width: windowSize.width >= 640 ? size : mSize || size
        }}
        onClick={onClick}
        ref={ref}
      >
        {!hovered && (
          <img alt="" src={windowSize.width >= 640 ? icon : mIcon || icon} />
        )}
        {hovered && iconHover && <img alt="" src={iconHover} />}
      </CircleContainer>
    </Link>
    )
  } else {
    return (
    <CircleContainer
      style={{
        background: !hovered
          ? windowSize.width >= 640
            ? color
            : mColor || color
          : colorHover || '#EEEEEE',
        padding: padding || 0,
        height: windowSize.width >= 640 ? size : mSize || size,
        width: windowSize.width >= 640 ? size : mSize || size
      }}
      onClick={onClick}
      ref={ref}
    >
      {!hovered && (
        <img alt="" src={windowSize.width >= 640 ? icon : mIcon || icon} />
      )}
      {hovered && iconHover && <img alt="" src={iconHover} />}
    </CircleContainer>
    )
  }
}
export const Rounded = ({
  color,
  colorHover,
  icon,
  iconHover,
  mColor,
  mIcon,
  mSize = '35px',
  onClick,
  padding = '5px',
  size = '35px'
}: Props) => {
  const windowSize = getWindowSize()
  const [hovered, setHovered] = useState(false)
  const ref = useRef(null)
  useEffect(() => {
    const mouseOver = () => setHovered(true)
    const mouseOut = () => setHovered(false)
    ref.current && ref.current.addEventListener('mouseover', mouseOver)
    ref.current && ref.current.addEventListener('mouseout', mouseOut)
    return () => {
      ref.current && ref.current.removeEventListener('mouseover', mouseOver)
      ref.current && ref.current.removeEventListener('mouseout', mouseOut)
    }
  }, [])
  return (
    <RoundContainer
      style={{
        background: !hovered
          ? windowSize.width >= 640
            ? color
            : mColor || color
          : colorHover || '#EEEEEE',
        padding: padding || 0,
        height: windowSize.width >= 640 ? size : mSize || size,
        width: windowSize.width >= 640 ? size : mSize || size
      }}
      onClick={onClick}
      ref={ref}
    >
      {!hovered && (
        <img alt="" src={windowSize.width >= 640 ? icon : mIcon || icon} />
      )}
      {hovered && iconHover && <img alt="" src={iconHover} />}
    </RoundContainer>
  )
}
