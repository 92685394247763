import { useEffect, useRef } from 'react'

function useClickOutside (handler) {
  const ref = useRef(null)
  useEffect(() => {
    const mouseDownHandler = (event) => {
      event.stopPropagation()
      if (!ref.current.contains(event.target)) handler()
    }
    document.addEventListener('mousedown', mouseDownHandler)
    return () => document.removeEventListener('mousedown', mouseDownHandler)
  }, [])
  return ref
}

export default useClickOutside
