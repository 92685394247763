import Navigation from './navigation'

import { getGeneralState, setSidebar } from '@/general/state'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const docs = () => {
  const { sidebarState } = useSelector(getGeneralState)
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(setSidebar(false))

  const [menu, setMenu] = useState([])
  useEffect(async () => {
    const response = await fetch('/api/docs')
    const data = await response.json()
    setMenu(data.menu)
  }, [])

  return (
    <div className={`
      lg:pt-10 bg-red-500 
      lg:grid content-start gap-8 fixed lg:relative
      lg:w-72 h-full overflow-hidden z-10 transition-all min-h-full
      ${sidebarState ? 'w-5/6' : 'w-0'}
      ${sidebarState ? 'border-r-4 border-red-400' : 'border-0'}
    `}>
        <div className='flex justify-end p-5 mb-5 lg:hidden cursor-pointer'>
          <img src="/menu/add.png" alt="" className='cursor-pointer' onClick={toggleSidebar} />
        </div>
        <img className='w-2/3 mx-auto mb-5' src="/media/logo/main-round.png" alt="" />
        <Navigation menu={menu} />
    </div>
  )
}

export default docs
