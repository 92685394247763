import React from 'react'
import SocialLogin from 'react-social-login'

import styled from 'styled-components'
const Button = styled.button`
    padding-bottom:.5em;
    padding-top:.5em;
`

class SocialButton extends React.Component {
  render () {
    const { children, triggerLogin, ...props } = this.props
    return (
        <Button onClick={triggerLogin} {...props}>
          {children}
        </Button>
    )
  }
}

export default SocialLogin(SocialButton)
